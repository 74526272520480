/* eslint-disable no-underscore-dangle */ /* eslint-disable no-console */
<template>
  <div class="destination-section">
    <headerCustom></headerCustom>
    <div class="template main-content sidenav-part ng-scope">
      <h3>Expert Talks</h3>
      <div class="widget">
        <div class="destination-content">
          <b-form
            v-promise-btn="{ action: 'submit' }"
            @submit.prevent="updateExpert"
            @reset.prevent="resetForm()"
          >
            <div>
              <div class="font-18 py-3">
                Information
                <span class="font-12">(* Required information)</span>
              </div>
              <!-- name -->
              <div>
                <b-form-group>
                  <label>Name*</label>
                  <b-input
                    v-model="$v.expertData.name.$model"
                    :state="validateState('name')"
                    aria-describedby="input-name-live-feedback"
                    placeholder="Enter Name"
                  ></b-input>

                  <b-form-invalid-feedback id="input-name-live-feedback"
                    >Name is required.</b-form-invalid-feedback
                  >
                </b-form-group>
              </div>

              <!-- designaiton -->
              <div>
                <b-form-group>
                  <label>Designation*</label>
                  <b-input
                    v-model="$v.expertData.designation.$model"
                    :state="validateState('designation')"
                    aria-describedby="input-designation-live-feedback"
                    placeholder="Enter Designation"
                  ></b-input>

                  <b-form-invalid-feedback id="input-designation-live-feedback"
                    >Designation is required.</b-form-invalid-feedback
                  >
                </b-form-group>
              </div>
              <!-- description -->
              <div class="pt-3">
                <b-form-group>
                  <label>Description*</label>
                  <br />
                  <b-form-textarea
                    v-model="$v.expertData.description.$model"
                    :state="validateState('description')"
                    aria-describedby="input-content-live-feedback"
                    id="textarea"
                    placeholder="Enter something..."
                    rows="3"
                    max-rows="6"
                  ></b-form-textarea>

                  <b-form-invalid-feedback id="input-content-live-feedback"
                    >Description is required.</b-form-invalid-feedback
                  >
                </b-form-group>
              </div>
              <!-- attachment -->
              <div class="pt-2">
                <b-form-group>
                  <label>Video</label>
                  <br />
                  <b-button
                    :disabled="disableVideoUpload"
                    border-0
                    class="px-5 upload-btn"
                    @click="$refs.fileInput2.click()"
                    >Upload</b-button
                  >
                  <input
                    ref="fileInput2"
                    type="file"
                    multiple
                    style="display:none;"
                    @change="onVideoChange($event.target.name, $event.target.files)"
                  />
                  <!-- <div
                    v-if="
                      !$v.expertData.video.required &&
                        $v.expertData.video.$error &&
                        $v.expertData.video.$touch
                    "
                    class="red error-text py-1"
                  >
                    Attachment is required
                  </div> -->
                </b-form-group>

                <b-row gutters>
                  <b-col md="2" cols="4" v-for="(video, key) in hotelVideos" :key="video">
                    <div>
                      <div @click="removeVideo(key)" class="icon-section z-index-screen">
                        <font-awesome-icon :icon="['fas', 'times']" class="red font-12" />
                      </div>
                      <video
                        :src="video"
                        alt="your video"
                        type="video/webm"
                        width="100%"
                        class="videos ml-3"
                      />
                    </div>
                  </b-col>
                </b-row>
              </div>
            </div>
            <div v-if="showError" class="error-text pt-2 text-center">{{ message }}</div>
            <div class="d-flex justify-content-center pt-3">
              <div>
                <b-button variant="success" type="submit">Update</b-button>
              </div>
              <div>
                <b-button variant="danger" type="reset" class="ml-5">Cancel</b-button>
              </div>
            </div>
          </b-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import headerCustom from "@/components/Header.vue";
import service from "@/service/apiservice";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
export default {
  components: {
    headerCustom
  },
  data() {
    return {
      videocount: 0,
      hotelVideos: [],
      videoUrl: "",
      videoFile: "",
      videoName: "",
      s3URL: "",
      disableVideoUpload: false,
      expertData: {
        name: "",
        designation: "",
        description: "",
        video: []
      },

      showError: false,
      message: "",
      expertId: ""
    };
  },
  validations: {
    expertData: {
      name: {
        required
      },
      designation: {
        required
      },
      description: {
        required
      }
      //   video: {
      //     required
      //   }
    }
  },
  created() {
    this.expertId = this.$route.params.id;
    if (this.expertId) {
      this.getOneExpert();
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.expertData[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.$router.push({ name: "viewExpertTalks" });
    },
    getOneExpert() {
      service.getOneExpert(this.expertId, result => {
        this.expertData = result.data;
        this.hotelVideos = result.data.video;
      });
    },
    onVideoChange(videoName, videoList) {
      const videoArray = [];
      // Convert from fileList to array

      for (let i = 0; i < videoList.length; i++) {
        videoArray.push(videoList[i]);
      }
      const newVideos = videoArray.map((videoFile, index) => ({
        videoFile,
        videoURL: URL.createObjectURL(videoFile),
        name: videoFile.name,
        key: `${new Date().getTime()}-${index}`
      }));
      if (newVideos.length >= 1) {
        for (let i = 0; i < newVideos.length; i++) {
          this.videoUrl = newVideos[i].videoURL;
          this.videoName = newVideos[i].name;
          this.videoFile = newVideos[i].videoFile;

          const videoSize = this.videoFile.size / (1024 * 1024);

          if (videoSize > 100) {
            this.$toaster.error("File Size should not be greater than 100 Mb.");
          } else {
            this.onVideo();
          }
        }
      } else {
        this.$toaster.error("Maximum limit of selection of video file is " + this.videocount);
      }
    },
    onVideo() {
      this.disableVideoUpload = true;

      const fd = new FormData();
      fd.append("file", this.videoFile, this.videoFile.name);

      service.awsExpertVideoUpload(fd, awsResponse => {
        if (awsResponse.status === 200) {
          this.disableVideoUpload = false;
          const predictionResults = awsResponse.data;
          this.s3URL = predictionResults.file;
          this.hotelVideos.push(this.s3URL);
          this.expertData.video = this.hotelVideos;
        } else {
          this.disableVideoUpload = "false";
          this.$toaster.danger("Video Not Saved");
        }
      });
    },
    removeVideo(index) {
      this.hotelVideos.splice(index, 1);
    },
    updateExpert() {
    
      this.$v.expertData.$touch();
      if (this.$v.expertData.$anyError) {
        return;
      } else {
        return new Promise((resolve, reject) => {
          service.updateExpert(this.expertData, this.expertId, resultData => {
            if (resultData.status == 200) {
              this.$toaster.success("Expert Talks updated");
              this.$router.push({ name: "viewExpertTalks" });
              resolve();
            } else {
              reject();
            }
          });
        });
      }
    }
  }
};
</script>
<style lang="scss">
@import "src/assets/scss/variables";
.destination-section {
  .destination-content {
    padding: 20px 20px;
  }
}
.red {
  color: red;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
